import React, { Component } from 'react';
import './recent-posts.scss';
import RecentList from './recent-list'


class RecentPosts  extends Component {

    recentList;

    constructor(props) {
        super(props);

        this.recentList = [
            {
                src: 'http://via.placeholder.com/110x75',
                date: 'Sep 12, 2018',
                desc: 'Success is not a good teacher failure makes you humble.'
            },
            {
                src: 'http://via.placeholder.com/110x75',
                date: 'Sep 12, 2018',
                desc: 'Success is not a good teacher failure makes you humble.'
            },
            {
                src: 'http://via.placeholder.com/110x75',
                date: 'Sep 12, 2018',
                desc: 'Success is not a good teacher failure makes you humble.'
            }
        ];

    }

    render(){
        return(
            <div className="widget recent-posts pb-20">
                <h4 className="widget-title h-title">Recent Posts</h4>
                <div className="widget-body pt-30 pb-5">
                    <div className="recent-post media">
                    {
                        this.recentList.map((list, index, props) => {
                            return (<RecentList {...list} key={`list-${index}`} />);
                        })
                    }
                    </div>
                </div>
            </div>
        )
    }

}

export default RecentPosts;