import React, { Component } from "react";
import BannerComponent from "../../components/banner/banner";
import TeamMemberComponent from "../../components/team-member/team-member";
import EasyTransition from "react-easy-transition";

import "./about.scss";

import { canUseWebP } from "../../helpers";
import AboutHeader from "../../images/aboutHeader.jpg";
import AboutHeaderWebP from "../../images/aboutHeader.webp";
import willImage from "../../images/team/will.jpg";
import mikeImage from "../../images/team/mike.jpg";
import danImage from "../../images/team/dan.jpg";
import kyleImage from "../../images/team/kyle.jpg";
import feiImage from "../../images/team/fei.jpg";
import randyImage from "../../images/team/randy.jpg";
import leahImage from "../../images/team/leah.jpg";
import mattImage from "../../images/team/matt.jpg";
import wafiImage from "../../images/team/wafi.jpg";
import wendiImage from "../../images/team/wendi.jpg";
// import chillaImage from "../../images/team/chilla.jpg";
// import andrewImage from "../../images/team/andrew.jpg";
// import justinImage from "../../images/team/justin.jpg";

class AboutComponent extends Component {
  members;

  constructor(props) {
    super(props);

    this.members = [
      {
        image: willImage,
        name: "Will Graham",
        position: "Lead Developer"
      },
      {
        image: mikeImage,
        name: "Mike Zoubarev",
        position: "Lead Software Engineer"
      },
      {
        image: danImage,
        name: "Dan Hines",
        position: "Senior Project Manager"
      },
      {
        image: kyleImage,
        name: "Kyle Windsor",
        position: "Full Stack Developer"
      },
      {
        image: feiImage,
        name: "Fei Long",
        position: "Web Developer"
      },
      {
        image: randyImage,
        name: "Randy Phalla",
        position: "Front End Developer"
      },
      {
        image: leahImage,
        name: "Leah Froats",
        position: "UI / UX Designer"
      },
      {
        image: mattImage,
        name: "Matt Asselstine",
        position: "Web / AR Developer"
      },
      {
        image: wafiImage,
        name: "Wafi Mardini",
        position: "Front End Developer / 3D Artist"
      },
      {
        image: wendiImage,
        name: "Wendi Zhang",
        position: "Front End Developer"
      }
      //   {
      //     image: chillaImage,
      //     name: "Chilla Tenga",
      //     position: "Software Engineer"
      //   },
      //   {
      //     image: andrewImage,
      //     name: "Andrew Lampert",
      //     position: "Software Engineer"
      //   },

      //   {
      //     image: justinImage,
      //     name: "Justin Bettencourt",
      //     position: "3D Artist"
      //   }
    ];

    this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
    return null;
  }

  render() {
    const teamMembers = this.members.map((props, index) => {
      return <TeamMemberComponent key={`item-${index}`} {...props} />;
    });

    return (
      <EasyTransition
        path={"./about.js"}
        initialStyle={{ opacity: 0, marginTop: 30 }}
        transition="all 300ms linear"
        finalStyle={{ opacity: 1, marginTop: 0 }}
      >
        <main className="main-content">
          <div className="page-container">
            <BannerComponent
              img={canUseWebP() ? AboutHeaderWebP : AboutHeader}
              title="About Us"
              paragraph="We have built up a team of digital professionals, each committed to remaining at the forefront of digital technology."
            />

            <section
              className="section our-process-info pt-60 pb-20"
              id="services"
            >
              <div className="container">
                <div className="row mb-30">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <p>
                      A London, ON based company, Devlift Media is a full
                      service design and development agency. Here at Devlift, we
                      pride ourselves on leveraging the newest and most relevant
                      development technologies to enhance our client's digital
                      presence and help future proof their business.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="section meet-our-team our-team-three ptb-60"
              id="team"
            >
              <div className="container">
                <div className="row mb-40">
                  <div className="col-lg-7 col-md-8 col-sm-10 col-xs-12 col-xs-center t-center">
                    <div className="card">
                      <h2 className="t-uppercase">Meet the Team</h2>
                      <p>
                        The people of Devlift are smart, creative and ambitious.
                        They embrace innovation and find unexpected solutions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="team-members row">{teamMembers}</div>
              </div>
            </section>
          </div>
        </main>
      </EasyTransition>
    );
  }
}

export default AboutComponent;
