import React, { Component } from "react";
import EasyTransition from "react-easy-transition";
import { ParallaxProvider } from "react-scroll-parallax";

import BannerComponent from "../../components/banner/banner";
import ProcessItemComponent from "../../components/process-item/process-item";

import "./our-process.scss";

import processBannerImage from "../../images/services-headers/recruitment.jpg";

import stratImage from "../../images/process/strategy_process.svg";
import designImage from "../../images/process/design_process.svg";
import developImage from "../../images/process/development_process.svg";
import testImage from "../../images/process/testing_process.svg";
import deployImage from "../../images/process/delivery_process.svg";
import maintenaceImage from "../../images/process/maintenance_process.svg";

class OurProcessComponent extends Component {
  processItems;

  constructor(props) {
    super(props);

    this.processItems = [
      {
        image: stratImage,
        title: "01. Strategy",
        /* paragraph: "We believe in working in a highly collaborative and team-oriented environment rooted in human-centered thinking to give every team member the platform to more flexible, adaptable, quick and resourceful. We ensure the client interacts directly with the project team to build and maintain trust, anticipate problems and help reduce any gaps in communication throughout the partnership.", */

        paragraph:
          "We believe in working in a highly collaborative and team-oriented environment rooted in human-centered thinking to give every team member the platform to be more flexible, adaptable, quick and resourceful. We ensure the client interacts directly with the project team to build and maintain trust, anticipate problems and help reduce any gaps in communication throughout the partnership.",

        /* paragraph1: "Our strategic process includes a deep dive into the market, competitors, industry trends, and inspiration to understand your market and the factors that influence it. After we have a firm understanding of your business, we begin to plan what the project phases are going to be, what resources are going to be needed, and what it’ll take to get the job done. Once a strategy has been put in place and the kick-off meeting has completed, the design process will begin." */

        paragraph1:
          "Our strategic process includes a deep dive into the market, competitors, industry trends, and inspiration to understand your vision and the factors that influence it. After we have a firm understanding of your business, we begin to plan what the project phases are going to be, what resources are going to be needed, and what it’ll take to get the job done. Once a strategy has been put in place and the kick-off meeting has completed, the design process will begin."
      },
      {
        image: designImage,
        title: "02. Design",
        /* paragraph: 'For any project that involves a user interface, the designers begin by creating wireframes and interactive prototypes. Wireframes help to understand and establish the user flow by focusing first on the navigation and structure of the site or product. Interactive Prototypes will help designers explore and experiment with ideas, high light problems at an early stage, as well as check functionality and usability. Wireframes and Protoypes allow Devlift to show you what the project will look like before well before a final version making it easier to involve the you in the feedback process and manage your expectations', */
        paragraph:
          "For any project that involves a user interface, the designers begin by creating wireframes and interactive prototypes. Wireframes help to communicate and establish the user flow by focusing first on the navigation and structure of the site or product. Interactive prototypes will help designers explore and experiment with ideas, highlight problems at an early stage and verify functionality and usability. Wireframes and protoypes allow Devlift to show you what the project will look like well before a final version, making it easier to involve you in the feedback and refinement processes",
        haveRightSide: true
      },
      {
        image: developImage,
        title: "03. Development",
        /* paragraph: "Our developers are skilled problem-solvers that go beyond just making sure things work in a browser. Our team will implement a solution using the best technologies for the project. We can deliver both front-end and back-end solution, that ensure your application will set your business in the right direction.", */
        paragraph:
          "Our developers are skilled problem solvers that go beyond just making sure things work in a browser. Our team will implement a solution using the best technologies for the project. We can deliver both front-end and back-end solutions that ensure your application will set your business in the right direction.",

        /* paragraph1: "Designers and Developers work together iteratively and incrementally to ensure our team is delivering continuous deployment. Continuous deployment allows for rapid and safe delivery of what users need most, maintained communication within the team, and keeping you involved with every stage of development", */
        paragraph1:
          "Designers and developers work together iteratively and incrementally to ensure our team is delivering continuous deployment. Continuous deployment allows for rapid and safe delivery, frequent communication within the team, and continual client involvement with every stage of development",

        /* paragraph2: "Our development team is grounded and expertise involving best practices, architecture recommendations, programming methodologies and practices, application platforms and frameworks, and, technical standards and specifications." */
        paragraph2:
          "Our development team is comprised of personnel who are grounded and possess the necessary expertise for best design and development practices, architecture recommendations, programming methodologies, application frameworks and technical specifications."
      },
      {
        image: testImage,
        title: "04. Testing",
        /* paragraph: "No matter what type of project Devlift produces or creates, we all participate in making sure the work that gets out is as perfect as it can be. Working in an agile development environment allows for faster, more comprehensive end-to-end testing of the product prior to launch. We revise things as we learn more, discover flaws in our earlier work and endeavor to improve the design/readability/simplicity of our code.", */
        paragraph:
          "No matter what type of project Devlift produces or creates, we all participate in making sure the final product is as perfect as it can be. Working in an agile development environment allows for faster, more comprehensive end-to-end testing of the product prior to launch. We revise things as we learn more, discover flaws in our earlier work and endeavor to improve the design/readability/simplicity of our code.",
        paragraph1:
          "We believe that both testing and refactoring are essential to every project's speed and success which is why we perform both internal and user acceptance testing. Internal and user acceptance testing allow for us to verify that all systems are designed and developed to specification.",
        haveRightSide: true
      },
      {
        image: deployImage,
        title: "05. Deployment",
        /* paragraph: "Now you are ready to go live, while we cannot eliminate all bugs and hiccups, we can be quick to respond to any issues that may arise after launch a deploy urgent fixes. We can offer dedicated resources to monitor the application and make sure the deployment was successful. We also make sure clients have a clear understanding of what exactly went live and how to communicate it to customers." */
        paragraph:
          "Now you are ready to go live.  While we cannot eliminate all bugs and hiccups, we can be quick to respond to any issues that may arise after launch and deploy urgent fixes. We can offer dedicated resources to monitor the application and make sure the deployment was successful. We also make sure clients have a clear understanding of what exactly went live and how to communicate it to customers."
      },
      {
        image: maintenaceImage,
        title: "06. Maintenance",
        paragraph:
          "At Devlift, we are in the business of constant improvement and love using what we know to help further build out your product. We monitor our results and evolve, forming long-term relationships with you and your company. After launch we work closely with you to ensure that any issues that may arise are dealt with and that any further needs are completed.",
        haveRightSide: true
      }
    ];

    this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
    return null;
  }

  render() {
    const processItem = this.processItems.map((props, index) => {
      return <ProcessItemComponent key={`item-${index}`} {...props} />;
    });

    return (
      <EasyTransition
        path={"./our-process.js"}
        initialStyle={{ opacity: 0, marginTop: 30 }}
        transition="all 300ms linear"
        finalStyle={{ opacity: 1, marginTop: 0 }}
      >
        <ParallaxProvider>
          <main className="main-content">
            <div className="page-container">
              <BannerComponent
                img={processBannerImage}
                title="Our Process"
                paragraph="Dive into our process to see how our agile methodologies can benefit you."
              />
              <section
                className="section our-process-info pt-60 pb-20"
                id="services"
              >
                <div className="container">
                  <div className="row mb-30">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <p>
                        We provide the technical and creative expertise to
                        ensure a successful product launch. Your project may
                        involve a website, a web or mobile application, an
                        online interface or a clever use of an emerging
                        technology. Whatever the solution, we can help in terms
                        of ideation, rapid digital product development and
                        building dynamic teams and agile workflows.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              {processItem}
            </div>
          </main>
        </ParallaxProvider>
      </EasyTransition>
    );
  }
}

export default OurProcessComponent;
