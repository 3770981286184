import React, { Component } from "react";

import "./team-member.scss";

class TeamMemberComponent extends Component {
  render() {
    return (
      <div className="col-md-3 col-sm-6">
        <div className="single-member bg-white">
          <div className="single-member-header pos-r">
            {this.props.image ? (
              <img src={this.props.image} alt="{this.props.image}" />
            ) : <div className="placeholder"></div>}
            <div className="single-member-overlay overlay t-center">
              <div className="overlay member-overlay" />
              <div className="member-info pos-tb-center">
                <h2>{this.props.name}</h2>
                <p>{this.props.position}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamMemberComponent;
