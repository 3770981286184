import React, { Component } from "react";
import PropTypes from "prop-types";
import EasyTransition from "react-easy-transition";
import { ParallaxProvider } from "react-scroll-parallax";

import BannerComponent from "../../../components/banner/banner";
import AdditionalServiceListComponent from "../../../components/additional-service-list/additional-service-list";
import TimelineItemComponent from "../../../components/timeline-item/timeline-item";

import "./build-your-team.scss";

import { canUseWebP } from "../../../helpers";
import trainingBannerImage from "../../../images/services-headers/training.jpg";
import trainingBannerImageWebP from "../../../images/services-headers/training.jpg";

import consultationIcon from "../../../images/service_team/consultation.svg";
import implementationIcon from "../../../images/service_team/implementation.svg";
// import onboadingIcon from '../../../images/service_team/onboarding.svg';
import recruitmentIcon from "../../../images/service_team/recruitment.svg";
import trainingIcon from "../../../images/service_team/training.svg";
import transitionIcon from "../../../images/service_team/transition.svg";

import devIcon from "../../../images/services-small/dev_sm.svg";

class BuildYourTeamComponent extends Component {
  card;
  timelineItems;

  constructor(props) {
    super(props);

    this.timelineItems = [
      {
        reverse: true,
        image: consultationIcon,
        title: "Consultation",
        info:
          "We will conduct an on-site consultation to determine development needs and the requirements of the project.",
        offsetXMax: 20,
        offsetXMin: -20
      },
      {
        image: devIcon,
        title: "Development",
        info:
          "At Devlift, we employ a modern agile approach to development.  This process ensures a high frequency of deliverables and consistent communication throughout the development and feedback processess with clients.",
        offsetXMax: -20,
        offsetXMin: 20
      },
      /* {
            reverse: true,
            image: implementationIcon,
            title: 'Implementation',
            info: "During the candidate's time with us, they will be actively working on one of your company s initiatives. All output will be fully reviewed by one of our senior team members and the candidate will be available to collaborate at any time, both remotely and onsite.",
            offsetXMax: 20,
            offsetXMin: -20
        }, */
      /* {
            image: trainingIcon,
            title: 'Training',
            info: "During the candidate's time with us, they will be actively working on one of your company's initiatives. All output will be fully reviewed by one of our senior team members and the candidate will be available to collaborate at any time, both remotely and onsite.",
            offsetXMax: -20,
            offsetXMin: 20
        }, */
      {
        reverse: true,
        image: implementationIcon,
        title: "Implementation",
        info:
          "Once your project has hit version 1.0, there are still opportunities for value.  Capturing usage data through the implementation of analytics frameworks can provide meaningful data that will guide your development priorities in the future.",
        offsetXMax: 20,
        offsetXMin: -20
      },
      {
        image: recruitmentIcon,
        title: "Recruitment & Onboarding",
        info:
          "We can tailor your internal onboarding and recruitment procedures to traverse the use of all relevant software, development methodologies, and best practices.",
        offsetXMax: -20,
        offsetXMin: 20
      },
      {
        reverse: true,
        image: trainingIcon,
        title: "'Level-Up' Training",
        info:
          "During the candidate's time with us, they will be actively working on one of your company's initiatives. All output will be fully reviewed by one of our senior team members and the candidate will be available to collaborate at any time, both remotely and onsite.  This training aims to equip your team with the skills they need to continue to develop and maintain your project post-launch.",
        offsetXMax: 20,
        offsetXMin: -20
      },
      {
        image: transitionIcon,
        title: "Transition",
        info:
          "Once training has completed, a consultation will take place to ensure that the newly trained candidate has the skills they require and that the undergone training encompasses all of your requirements.",
        noLine: true,
        offsetXMax: -20,
        offsetXMin: 20
      }
    ];
    this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
    return null;
  }

  render() {
    const timelineItemsCard = this.timelineItems.map((props, index) => {
      return <TimelineItemComponent key={`item-${index}`} {...props} />;
    });

    return (
      <EasyTransition
        path={"./build-your-team.js"}
        initialStyle={{ opacity: 0, marginTop: 30 }}
        transition="all 300ms linear"
        finalStyle={{ opacity: 1, marginTop: 0 }}
      >
        <main className="main-content">
          <div className="page-container">
            <BannerComponent
              img={canUseWebP() ? trainingBannerImageWebP : trainingBannerImage}
              title="Build Your Team"
              paragraph="We can assist with recruitment, training and onboarding procedures for you and your growing company"
            />

            <section
              className="section our-process-info pt-60 pb-20"
              id="services"
            >
              <div className="container">
                <div className="row mb-30">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <p>
                      At Devlift Media, we can offer a wide range of services
                      for recruitment, training and onboarding procedures. We
                      aim to tailor these procedures to your needs to ensure
                      consistent company growth and the development of new
                      features for your application.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="section timeline-area pt-60 pb-20">
              <ParallaxProvider>
                <div className="container">{timelineItemsCard}</div>
              </ParallaxProvider>
            </section>
            <br />
            <AdditionalServiceListComponent />
          </div>
        </main>
      </EasyTransition>
    );
  }
}

BuildYourTeamComponent.propTypes = {
  cards: PropTypes.array,
  timelineItems: PropTypes.array
};

export default BuildYourTeamComponent;
