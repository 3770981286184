import React, { Component } from "react";
import EasyTransition from "react-easy-transition";
import { Container, Row, Col, Card } from "react-bootstrap";

import BannerComponent from "../../../components/banner/banner";
import AdditionalServiceListComponent from "../../../components/additional-service-list/additional-service-list";
import "./strategy.scss";

import { canUseWebP } from "../../../helpers";
import strategyBannerImage from "../../../images/services-headers/strategy.jpg";
import strategyBannerImageWebP from "../../../images/services-headers/strategy.webp";

class StrategyComponent extends Component {
  constructor(props) {
    super(props);

    this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
    return null;
  }

  render() {
    return (
      <EasyTransition
        path={"../strategy/strategy.js"}
        initialStyle={{ opacity: 0, marginTop: 30 }}
        transition="all 300ms linear"
        finalStyle={{ opacity: 1, marginTop: 0 }}
      >
        <main className="main-content">
          <div className="page-container">
            <BannerComponent
              img={canUseWebP() ? strategyBannerImageWebP : strategyBannerImage}
              title="Strategy"
              paragraph="We look at the whole picture to develop a vision, based on knowledge, research and experience"
            />

            <section
              className="section our-process-info pt-60 pb-20"
              id="services"
            >
              <Container>
                <Row className="mb-30">
                  <Col xs="12">
                    <p>
                      We take the time to listen to you and understand what you
                      want to achieve. We help businesses define or improve
                      their brand by learning about the market, competitors,
                      audience, and customer relations to solve emerging
                      marketing challenges. We look at the whole picture to
                      determine what is right for your business and audience.
                    </p>
                  </Col>
                </Row>
              </Container>
            </section>

            <section className="section our-strategy-item">
              <Container>
                <Row>
                  <Col md="6">
                    <Card>
                      <h2>Research and Discovery</h2>
                      <p>
                        Analysing insights about your brand and competitors to
                        help us produce campaigns and projects that are
                        relevant, engaging and effective for both our clients
                        and their users.
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <h2>Project Management</h2>
                      <p>
                        We work closely with you to develop and implement a
                        strategy that aligns with your company's goals. We pride
                        ourselves on offering our clients transparency
                        throughout the project with open lines of
                        communications.
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <h2>Competitive Analysis</h2>
                      <p>
                        Help to gain a better understanding of your target
                        audience and develop effective competitive strategies
                        that help us make better decisions for your project and
                        users
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <h2>Brand Analysis and Strategy</h2>
                      <p>
                        We build and create brands using strategic insights to
                        help define all aspects of your brand including
                        personality, purpose, and target audience.{" "}
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <h2>Content Strategy</h2>
                      <p>
                        Use data, design, voice and custom content writing to
                        deliver the right message
                      </p>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
            <br />
            <AdditionalServiceListComponent />
          </div>
        </main>
      </EasyTransition>
    );
  }
}

export default StrategyComponent;
