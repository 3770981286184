import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "./additional-service-list.scss";

import AdditionalServiceCardComponent from "../additional-service-card/additional-service-card";

import designIcon from "../../images/services-small/design_sm.svg";
import devIcon from "../../images/services-small/dev_sm.svg";
import markIcon from "../../images/services-small/marketing_sm.svg";
import teamIcon from "../../images/services-small/recruit_sm.svg";
import trainIcon from "../../images/services-small/train_sm.svg";
import strategyIcon from "../../images/services-small/strat_sm.svg";

class AdditionalServiceListComponent extends Component {
  cards = [
    {
      imageIcon: strategyIcon,
      title: "Strategy",
      link: "/strategy"
    },
    {
      imageIcon: designIcon,
      title: "Design",
      link: "/design"
    },
    {
      imageIcon: devIcon,
      title: "Development",
      link: "/development"
    },
    {
      imageIcon: markIcon,
      title: "Marketing",
      link: "/marketing"
    },
    {
      imageIcon: teamIcon,
      title: "Build Your Team",
      link: "/build-your-team"
    },
    {
      imageIcon: trainIcon,
      title: "Training",
      hasText: true,
      text: "Coming Soon"
    }
  ];
  additionalServices = this.cards.map((props, index) => {
    const { pathname } = this.props.location;
    if (props.link !== pathname) {
      return (
        <AdditionalServiceCardComponent key={`item-${index}`} {...props} />
      );
    }
    return null;
  });

  render() {
    return (
      <section
        className="section additional-services services-one pb-80"
        id="services"
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg="10" md="10" sm="10" xs="12">
              <h2 className="t-uppercase">Additional Services</h2>
            </Col>
          </Row>
          <br />
          <Row className="service-item justify-content-center">
            {this.additionalServices}
          </Row>
        </Container>
      </section>
    );
  }
}

export default withRouter(AdditionalServiceListComponent);
