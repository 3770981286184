import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class BlogListItem  extends Component {

    render(){
        return(
            <li>
                <Link to="#">{this.props.title}</Link>
            </li>
        )
    }
    
}

export default BlogListItem;