import React, { Component } from "react";
import PropTypes from "prop-types";
import { Parallax } from "react-scroll-parallax";

import "./timeline-item.scss";

class TimelineItemComponent extends Component {
  render() {
    return (
      <div>
        <div className="row timeline-items">
          <div
            className={
              this.props.reverse ? "col-sm-4 col-md-4" : "col-sm-2 col-md-2"
            }
          />
          <div className="col-sm-6 col-md-6">
            <Parallax
              className=""
              offsetXMax={this.props.offsetXMax}
              offsetXMin={this.props.offsetXMin}
              slowerScrollR
            >
              <div className="row timeline-item">
                <div className="col-xs-4 col-md-4">
                  <img src={this.props.image} alt="Icon" />
                </div>
                <div className="col-xs-8 col-md-8">
                  <h2>{this.props.title}</h2>
                  <p>{this.props.info}</p>
                </div>
              </div>
            </Parallax>
          </div>
          <div
            className={
              !this.props.reverse ? "col-sm-4 col-md-4" : "col-sm-2 col-md-2"
            }
          />
        </div>

        {!this.props.noLine && (
          <div className="row line-cont">
            <div className="col-md-12">
              <div className="line" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

TimelineItemComponent.propTypes = {
  reverse: PropTypes.bool,
  image: PropTypes.string,
  title: PropTypes.string,
  info: PropTypes.string
};

export default TimelineItemComponent;
