import React, { Component } from 'react';
import './blog-categories.scss';
import BlogListItem from '../blog-list-item/blog-list-item';

class BlogCategories  extends Component {

    items: any;

    constructor(props) {
        super(props);

        this.items = [
            {
                title: 'Design'
            },
            {
                title: 'UI Design'
            },
            {
                title: 'UX Design'
            },
            {
                title: 'Web Development'
            }
        ];

    }

    render(){
        return(
            <div className="widget categories-widget pb-20">
                <div className="widget-header">
                    <h4 className="widget-title h-title">Blog Categories</h4>
                </div>
                <div className="widget-body pt-30 pb-5">
                    <ul className="sidebar-list">
                        {
                            this.items.map((item, index, props) => {
                                return (<BlogListItem {...item} key={`item-${index}`}/>);
                            })
                        }

                    </ul>
                </div>
            </div>
        )
    }


}

export default BlogCategories;