import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import PropTypes from "prop-types";

import "./service-card.scss";

class ServiceCardComponent extends Component {
  static propTypes = {
    setMinWidth: PropTypes.bool
  };

  static defaultProps = {
    setMinWidth: false
  };

  render() {
    const { setMinWidth, title, link, paragraph, image } = this.props;
    return (
      <Col lg="4" md="6" xs="12">
        <div className={"service-single" + (setMinWidth ? " with-min" : "")}>
          <div className="service-content">
            <img src={image} alt="Icon" />
            <h2 className="t-uppercase mb-20">{title}</h2>
            <p>{paragraph}</p>
            {link ? (
              <Link to={link}>
                <i className="fa fa-arrow-circle-o-right" aria-hidden="true" />
              </Link>
            ) : (
              <div className="blank-item" />
            )}
          </div>
        </div>
      </Col>
    );
  }
}

export default ServiceCardComponent;
