import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class RecentList  extends Component {

    render() {
        return(
            <div className="recent-post media">
                <div className="post-thumb media-left">
                    <Link to="#">
                        <img className="media-object" src={this.props.src} alt=""/>
                    </Link>
                </div>
                <div className="media-body">
                    <div className="post-meta color-mid  mb-5 font-13">
                        <i className="fa fa-clock-o mr-5"></i>{this.props.date}
                    </div>
                    <h6 className="font-14">
                        <Link to="#">{this.props.desc}</Link>
                    </h6>
                </div>
            </div>
        )
    }

}

export default RecentList;