import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import EasyTransition from "react-easy-transition";
import Slider from "react-slick";
import PropTypes from "prop-types";

import { canUseWebP } from "../../helpers";
import AboutHeader from "../../images/aboutHeader.jpg";
import AboutHeaderWebP from "../../images/aboutHeader.webp";
import ServiceCardComponent from "../../components/service-card/service-card";

import videoBackground from "../../videos/DevliftVideocompressed.mp4";

import designIcon from "../../images/services icons/svg/design.svg";
import devIcon from "../../images/services icons/svg/development.svg";
import markIcon from "../../images/services icons/svg/marketing.svg";
import recruitIcon from "../../images/services icons/svg/recruitment.svg";
import stratIcon from "../../images/services icons/svg/strategy.svg";
import trainIcon from "../../images/services icons/svg/training.svg";

import chevroletLogo from "../../images/client_logos/Chevrolet-logo.png";
import hyundaiLogo from "../../images/client_logos/Hyundai_Motor_Company_logo.svg.png";
import toyotaLogo from "../../images/client_logos/Toyota-Logo-Transparent.png";
import samsungLogo from "../../images/client_logos/Samsung_Logo.png";
import omaLogo from "../../images/client_logos/oma-logo.png";
import corusLogo from "../../images/client_logos/corus_logo.png";
import eoneLogo from "../../images/client_logos/e_one.jpg";
import ytv from "../../images/client_logos/YTV_logo.png";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import "./home.scss";

class Home extends Component {
  serviceCards;

  constructor(props) {
    super(props);

    this.serviceCards = [
      {
        image: designIcon,
        title: "Design and UX",
        /* paragraph: 'Informed by research and driven by a strong understanding of User Experience to transmit our creative ideas and concepts.', */
        paragraph:
          "Informed by research and driven by a strong understanding of the user experience.  We will bring your vision to life.",
        link: "/design"
      },
      {
        image: devIcon,
        title: "Development",
        /* paragraph: 'Using the best features and functionalities, we use the most innovative technical solutions to deliver products and experiences.', */
        paragraph:
          "We use only the most innovative technical solutions to deliver the finest products and experiences",
        link: "/development"
      },
      {
        image: markIcon,
        title: "Marketing",
        /* paragraph: 'Through campaign planning and strategic marketing, we improve the way brands connect with audiences.', */
        paragraph:
          "Through campaign planning and strategic marketing, we improve the way your brand connects with audiences.",
        link: "/marketing"
      },
      {
        image: stratIcon,
        title: "Strategy and Planning",
        /* paragraph: 'We look at the whole picture to develop a vision, based on knowledge, research and experience.', */
        paragraph:
          "We look at the whole picture to develop your vision based on our knowledge, research and experience.",
        link: "/strategy"
      },
      {
        image: recruitIcon,
        title: "Build Your Team",
        /* paragraph: 'We look the whole picture to determine what is right for your business and audience.', */
        paragraph:
          "We can assist with recruitment, training and onboarding procedures for your growing company.",
        link: "/build-your-team"
      },
      {
        image: trainIcon,
        title: "Training",
        paragraph:
          "COMING SOON! Training is a new service we will be offering in the near future"
      }
    ];

    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      visible: false
    };

    this.scrollToTop();
  }

  // end from

  scrollToTop() {
    window.scrollTo(0, 0);
    return null;
  }

  soundOff() {
    const myVideo = document.getElementById("myVideo");
    myVideo.muted = true;
    this.setState({
      visible: !this.state.visible
    });
  }

  soundOn() {
    const myVideo = document.getElementById("myVideo");
    myVideo.muted = false;
    this.setState({
      visible: !this.state.visible
    });
  }

  componentDidMount() {
    // Decode entities in the URL
    // Sometimes a URL like #/foo#bar will be encoded as #/foo%23bar
    window.location.hash = window.decodeURIComponent(window.location.hash);
    const scrollToAnchor = () => {
      const hashParts = window.location.hash.split("#");
      if (hashParts.length >= 2) {
        const hash = hashParts.slice(-1)[0];
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "auto" });
        }
      }
    };
    scrollToAnchor();
    window.onhashchange = scrollToAnchor;
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      easing: "linear",
      autoplay: true,
      speed: 300,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 10000,
          settings: {
            slidesToShow: 4
          }
        }
      ]
    };

    const serviceCards = this.serviceCards.map((props, index) => {
      return (
        <ServiceCardComponent
          key={`item-${index}`}
          setMinWidth={true}
          {...props}
        />
      );
    });

    const { videoLoaded } = this.props;
    const { visible } = this.state;

    return (
      <EasyTransition
        path={"./home.js"}
        initialStyle={{ opacity: 0, marginTop: 30 }}
        transition="all 300ms linear"
        finalStyle={{ opacity: 1, marginTop: 0 }}
      >
        <main className="main-content">
          <div className="page-container">
            <section className="section hero-area t-center">
              <div className="hero-area-cont">
                <div className="hero-info">
                  <ScrollAnimation animateIn="fadeInUp" duration={1}>
                    <h2 className="t-uppercase">We Are Devlift</h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInDown" duration={2}>
                    <h5 className="alpha-90">A Creative Development Agency.</h5>
                  </ScrollAnimation>
                </div>
              </div>
              <div className="overlay" />
              <video
                id="myVideo"
                className="video-background"
                autoPlay
                loop
                muted
                preload="true"
                poster={canUseWebP() ? AboutHeaderWebP : AboutHeader}
                onCanPlay={videoLoaded}
                onError={videoLoaded}
              >
                <source src={videoBackground} type="video/mp4" />
              </video>

              <div className="volumeBtn">
                {visible && (
                  <button className="btn" onClick={this.soundOff.bind(this)}>
                    <i className="fa fa-volume-off" aria-hidden="true" />
                  </button>
                )}
                {!visible && (
                  <button className="btn" onClick={this.soundOn.bind(this)}>
                    <i className="fa fa-volume-up" aria-hidden="true" />
                  </button>
                )}
              </div>
            </section>

            <section
              className="section services-area services-one pt-60 pb-80"
              id="services"
            >
              <Container>
                <Row className="mb-30">
                  <Col sm="10" xs="12" className="col-xs-center mb-40">
                    <h2>Our Services</h2>
                    <p>
                      Starting a new project can be daunting, but our team has
                      the expertise you can count on. We work closely with your
                      business and audience to gain a full understanding of your
                      needs, challenges and opportunities. At Devlift, we are
                      always looking for innovative solutions to help provide
                      clarity and guidance while working with you to build your
                      vision. We will ensure that your business reaches its
                      goals.
                    </p>
                  </Col>
                </Row>
                <Row>{serviceCards}</Row>
              </Container>
            </section>

            <section className="section fun-facts-area ptb-80 color-white">
              <div className="overlay bg-overlay alpha-60" />
              <Container>
                <Row>
                  <Col>
                    <div className="t-center">
                      <h1>Our Process</h1>
                    </div>
                  </Col>
                </Row>
                <br />
                <br />
                <Row className="our-process-steps">
                  <Col lg="2" md="4" sm="6" xs="12">
                    <div className="counter-box t-center">
                      <p className="counter">
                        <span>1</span>
                      </p>
                      <h2>Strategy</h2>
                    </div>
                  </Col>
                  <Col lg="2" md="4" sm="6" xs="12">
                    <div className="counter-box t-center">
                      <p className="counter">
                        <span>2</span>
                      </p>
                      <h2>Design</h2>
                    </div>
                  </Col>
                  <Col lg="2" md="4" sm="6" xs="12">
                    <div className="counter-box t-center">
                      <p className="counter">
                        <span>3</span>
                      </p>
                      <h2>Development</h2>
                    </div>
                  </Col>
                  <Col lg="2" md="4" sm="6" xs="12">
                    <div className="counter-box t-center">
                      <p className="counter">
                        <span>4</span>
                      </p>
                      <h2>Test</h2>
                    </div>
                  </Col>
                  <Col lg="2" md="4" sm="6" xs="12">
                    <div className="counter-box t-center">
                      <p className="counter">
                        <span>5</span>
                      </p>
                      <h2>Deploy</h2>
                    </div>
                  </Col>
                  <Col lg="2" md="4" sm="6" xs="12">
                    <div className="counter-box t-center">
                      <p className="counter">
                        <span>6</span>
                      </p>
                      <h2>Maintain</h2>
                    </div>
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <Col className="paragraphs">
                    <div className="t-center">
                      <p>
                        We provide the technical and creative expertise to
                        ensure a successful product launch. Your project may
                        involve a website, mobile or web application, an online
                        interface or a innovative use for an emerging
                        technology. Whatever the solution, we can help in terms
                        of ideation, rapid digital product development and
                        building dynamic teams and agile workflows.
                      </p>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <div className="t-center">
                      <Link className="btn" to="/our-process">
                        Learn More
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>

            <section className="section clients-area">
              <Container>
                <Row>
                  <Col>
                    <Slider {...settings}>
                      <div className="client-item chevroletLogo">
                        <img src={chevroletLogo} alt="Chevrolet Logo" />
                      </div>
                      <div className="client-item">
                        <img src={hyundaiLogo} alt="Hyundai Logo" />
                      </div>
                      <div className="client-item toyotaLogo">
                        <img src={toyotaLogo} alt="Toyota Logo" />
                      </div>
                      <div className="client-item samsungLogo">
                        <img src={samsungLogo} alt="Samsung_Logo" />
                      </div>
                      <div className="client-item omaLogo">
                        <img src={omaLogo} alt="omaLogo" />
                      </div>
                      <div className="client-item corusLogo">
                        <img src={corusLogo} alt="corusLogo" />
                      </div>
                      <div className="client-item eoneLogo">
                        <img src={eoneLogo} alt="eoneLogo" />
                      </div>
                      <div className="client-item ytvLogo">
                        <img src={ytv} alt="ytvLogo" />
                      </div>
                    </Slider>
                  </Col>
                </Row>
              </Container>
            </section>

            <section className="section contact-us-area ptb-60" id="contact">
              <div className="container">
                <div className="row mb-40">
                  <div className="col-lg-7 col-md-8 col-sm-10 col-xs-12 col-xs-center t-center">
                    <h2>Get In Touch</h2>
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-md-4 col-sm-12">
                    <div className="t-center p-20">
                      <div className="contact-icon">
                        <a href="tel:+18333385438">
                          <i className="fa fa-phone" aria-hidden="true" />
                        </a>
                      </div>
                      <h3 className="mb-10">
                        <a href="tel:+18333385438">+1 (833) 338-5438</a>
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="t-center p-20">
                      <div className="contact-icon">
                        <a href="mailto:info@devlift.com">
                          <i className="fa fa-envelope-o" aria-hidden="true" />
                        </a>
                      </div>
                      <h3 className="mb-10">
                        <a href="mailto:info@devlift.com">info@devlift.com</a>
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="t-center p-20">
                      <div className="contact-icon">
                        <a
                          href="https://www.google.ca/maps?q=240+waterloo+street+london&um=1&ie=UTF-8&sa=X&ved=0ahUKEwjr3Z3zjorXAhWMx4MKHf8qAXkQ_AUICigB"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-map-marker" aria-hidden="true" />
                        </a>
                      </div>
                      <h3 className="mb-10">
                        <a
                          href="https://www.google.ca/maps?q=240+waterloo+street+london&um=1&ie=UTF-8&sa=X&ved=0ahUKEwjr3Z3zjorXAhWMx4MKHf8qAXkQ_AUICigB"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          240 Waterloo St
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>

                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2918.855057132788!2d-81.24126451174207!3d42.981326785418716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882ef21caa663365%3A0xb8309e59da001e4d!2sDevlift+Media!5e0!3m2!1sen!2sca!4v1515111283525"
                  style={{
                    width: "100%",
                    height: "450px",
                    border: 0,
                    pointerEvents: "none !important"
                  }}
                  frameBorder="0"
                  allowFullScreen
                  title="Devlift Location"
                />
              </div>
            </section>
          </div>
        </main>
      </EasyTransition>
    );
  }
}

Home.propTypes = {
  videoLoaded: PropTypes.func
};

export default Home;
