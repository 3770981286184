import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import BannerComponent from "../../../components/banner/banner";

import { canUseWebP } from "../../../helpers";
import marketingBannerImage from "../../../images/services-headers/marketing.jpg";
import marketingBannerImageWebP from "../../../images/services-headers/marketing.webp";
import AdditionalServiceListComponent from "../../../components/additional-service-list/additional-service-list";
import EasyTransition from "react-easy-transition";
import "./marketing.scss";

class Marketing extends Component {
  constructor(props) {
    super(props);
    this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
    return null;
  }

  render() {
    return (
      <EasyTransition
        path={"../design/design.js"}
        initialStyle={{ opacity: 0, marginTop: 30 }}
        transition="all 300ms linear"
        finalStyle={{ opacity: 1, marginTop: 0 }}
      >
        <main className="main-content">
          <div className="page-container">
            <BannerComponent
              img={
                canUseWebP() ? marketingBannerImageWebP : marketingBannerImage
              }
              title="Marketing"
              paragraph="Through campaign planning and strategic marketing, we improve the way brands connect with audiences."
            />

            <section
              className="section our-process-info pt-60 pb-20"
              id="services"
            >
              <Container>
                <Row className="mb-30">
                  <Col>
                    <p>
                      We help to build a strong relationship with your audience
                      and fans through strategic planning with valuable
                      information that attracts attention, drives traffic and
                      builds your business. We help to raise awareness of your
                      business with smart content strategies and by engaging
                      with your audience to create a connection with your brand.
                    </p>
                  </Col>
                </Row>
              </Container>
            </section>

            <section className="section our-marketing-item">
              <Container>
                <Row className="row-tb-15 mt-40">
                  <Col md="6">
                    <Card>
                      <h2>Digital and Print Marketing</h2>
                      <p>
                        campaign planning and strategic marketing, we help
                        brands launch new products and services, grow interest
                        in existing products and extend reach or introduce
                        businesses.
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <h2>Content Marketing</h2>
                      <p>
                        We want to make sure that content is created with a
                        purpose and is shown to the right people, in the right
                        place at the right time.
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <h2>Social media Marketing</h2>
                      <p>
                        create the strategy, content, campaigns, and distribute
                        the advertising on a number of different platforms
                        including Google, Facebook, Instagram
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <h2>Analytics / SEO</h2>
                      <p>
                        We work to extract valuable insights from applications
                        and mobile apps to measure and optimize for greater
                        conversions and revenue
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <h2>Promotional Videos</h2>
                      <p>
                        Leveraging user-friendly content creation, we aim to
                        break through the noise to engage with your audience
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <h2>Google Adwords</h2>
                      <p>
                        Utilize google adwords for online advertising to help
                        boost website traffic and sales.
                      </p>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
            <br />
            <AdditionalServiceListComponent />
          </div>
        </main>
      </EasyTransition>
    );
  }
}

export default Marketing;
