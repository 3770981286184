import React, { Component } from "react";
import logo from "../../images/devlift_logo/logo_white.png";
import "./footer.scss";

class Footer extends Component {
  render() {
    return (
      <footer className="main-footer pt-60">
        <div className="container pb-60">
          <div className="footer-widgets">
            <div className="row row-tb-20 row-masnory">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="widget pt-5">
                  <figure className="mb-10 t-center">
                    <img src={logo} width="170" alt="Devlift Media Logo" />
                  </figure>
                  <ul className="social-icons social-icons--colored list-inline pt-5  t-center">
                    <li className="social-icons__item">
                      <a
                        href="https://www.facebook.com/devlift"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="social-icons__item">
                      <a
                        href="https://www.twitter.com/DevLiftMedia"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-footer">
          <div className="container">
            <h6 className="copyright">
              Copyright &copy; DevLift Media 2019 | All right Reserved
            </h6>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
