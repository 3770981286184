import React, { Component } from "react";
import "./banner.scss";

class BannerComponent extends Component {
  render() {
    return (
      <section
        className="section banner"
        style={{ backgroundImage: `url(${this.props.img})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-8 col-xs-12 col-xs-center t-center mb-40">
              <h2 className="t-uppercase">{this.props.title}</h2>
              <p className="alpha-90">{this.props.paragraph}</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BannerComponent;
