import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Helmet } from 'react-helmet';

import Header from "./components/header/header";
import Footer from "./components/footer/footer";

import Home from "./pages/home/home";
import AboutComponent from "./pages/about/about";
import StrategyComponent from "./pages/services/strategy/strategy";
import OurProcessComponent from "./pages/our-process/our-process";
import BuildYourTeamComponent from "./pages/services/build-your-team/build-your-team";
import TheLab from "./pages/the-lab/the-lab";
import Design from "./pages/services/design/design";
import Development from "./pages/services/development/development";
import Marketing from "./pages/services/marketing/marketing";

import "./App.scss";
const HEADER_BREAKPOINT = 4;
const SHOWTOP_BREAKPOINT = 200;

const body = document.querySelector("body");
const loader = document.getElementById("preloader");
const showLoader = () => {
  body.classList.add("preloader-active");
  if (loader) {
    loader.style.display = "";
  }
};

// if you want to show the loader when React loads data again
const hideLoader = () => {
  body.classList.remove("preloader-active");
  if (loader) {
    loader.style.display = "none";
  }
};

class App extends Component {
  constructor() {
    super();
    showLoader();
  }
  state = {
    showCollapsed: false,
    showScrollToTop: false,
    isLoading: true
  };
  render() {
    const { showCollapsed, showScrollToTop } = this.state;
    return (
      <div id="pageWrapper" className="page-wrapper t-center t-sm-left">
        <Helmet>
        <script type="application/ld+json">{`{ 
        "@context" : "http://schema.org",
        "@type" : "Organization",
        "name" : "Devlift Media",
        "url" : "https://devlift.com/",
        "sameAs" : [ "http://www.facebook.com/devlift",
          "https://twitter.com/DevLiftMedia",
          "https://devlift.io",
          "https://www.linkedin.com/company/devliftmedia",
          "https://www.instagram.com/devlift.media"]
          }`}
        </script>
        </Helmet>
        <Header showCollapsed={showCollapsed} />
        <Route
          exact={true}
          path="/"
          render={() => <Home videoLoaded={this.videoLoaded} />}
        />
        <Route path="/about" component={AboutComponent} />
        <Route path="/strategy" component={StrategyComponent} />
        <Route path="/our-process" component={OurProcessComponent} />
        <Route path="/build-your-team" component={BuildYourTeamComponent} />
        <Route path="/the-lab" component={TheLab} />
        <Route path="/design" component={Design} />
        <Route path="/development" component={Development} />
        <Route path="/marketing" component={Marketing} />
        <Footer />
        <div
          id="backTop"
          onClick={this.scrollToTop}
          className={
            "back-top is-hidden-sm-down " + (showScrollToTop ? " show" : "")
          }
        >
          <i className="fa fa-angle-up" aria-hidden="true" />
        </div>
      </div>
    );
  }
  componentDidMount() {
    document.addEventListener("scroll", this.trackScrolling);
    setTimeout(() => {
      hideLoader();
    }, 2000);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  videoLoaded = () => {
    hideLoader();
  };

  scrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  trackScrolling = () => {
    if (window.pageYOffset > HEADER_BREAKPOINT) {
      this.setState({ showCollapsed: true });
    } else {
      this.setState({ showCollapsed: false });
    }

    if (window.pageYOffset > SHOWTOP_BREAKPOINT) {
      this.setState({ showScrollToTop: true });
    } else {
      this.setState({ showScrollToTop: false });
    }
  };
}

export default App;
