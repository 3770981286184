import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import BannerComponent from "../../../components/banner/banner";
import { canUseWebP } from "../../../helpers";
import developmentBannerImage from "../../../images/services-headers/development.jpg";
import developmentBannerImageWebP from "../../../images/services-headers/development.webp";
import AdditionalServiceListComponent from "../../../components/additional-service-list/additional-service-list";
import EasyTransition from "react-easy-transition";

import "./development.scss";

class Development extends Component {
  constructor(props) {
    super(props);

    this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
    return null;
  }

  render() {
    return (
      <EasyTransition
        path={"../design/design.js"}
        initialStyle={{ opacity: 0, marginTop: 30 }}
        transition="all 300ms linear"
        finalStyle={{ opacity: 1, marginTop: 0 }}
      >
        <main className="main-content">
          <div className="page-container">
            <BannerComponent
              img={
                canUseWebP()
                  ? developmentBannerImageWebP
                  : developmentBannerImage
              }
              title="Development"
              paragraph="Using the best features and functionalities, we use the most innovative technical solutions to deliver products and experiences"
            />

            <section
              className="section our-process-info pt-60 pb-20"
              id="services"
            >
              <Container>
                <Row className="mb-30">
                  <Col>
                    <p>
                      We provide the technical and creative expertise to build
                      stunning digital experiences. Your project may involve a
                      website, mobile or web application, an online interface or
                      a innovative use for an emerging technology. Our design
                      and development departments work as one to transform your
                      ideas into realities, whatever the solution.
                    </p>
                  </Col>
                </Row>
              </Container>
            </section>

            <section className="section our-development-item">
              <Container>
                <Row className="row-tb-15 mt-40">
                  <Col md="6">
                    <Card className="card-fixed">
                      <h2>QA Testing</h2>
                      <p>
                        Comprehensive testing occurs for each project. This
                        ensures consistency and quality thereby creating
                        dependable digital solutions and satisfied clients.
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card className="card-fixed">
                      <h2>Third-Party Applications & API’s</h2>
                      <p>
                        We seamlessly integrate third-party applications,
                        services and APIs to increase the functionality and
                        stability of your project from the start.{" "}
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card className="card-fixed">
                      <h2>Virtual and Augmented Reality</h2>
                      <p>
                        At Devlift we use virtual and augmented reality to
                        deliver a unique experience for a range of industries.
                        We help companies to bring stories to life with
                        interactive and immersive ways to showcase their product
                        and engage with their audience.
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card className="card-fixed">
                      <h2>Support and Maintenance</h2>
                      <p>
                        We don’t say goodbye after we deploy your project, we
                        continue to provide support and feature management to
                        make sure your project remains up-to-date and continues
                        to perform successfully.
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card className="card-fixed">
                      <h2>Development Consultation</h2>
                      <p>
                        From development and architectural guidance, to planning
                        and implementation, we work with you to help establish a
                        right-the-first-time approach. With pre-defined end
                        results that enable us to help clients understand what
                        it takes for their project to be successful. We aim to
                        put together the best team for the project and achieve
                        flexibility in development and operations.
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card className="card-fixed">
                      <h2>Full Stack Development </h2>
                      <p>
                        Devlift is experienced in a wide spectrum of
                        technologies from front-end to back-end and everything
                        in between. Our team is skilled in building software for
                        web and mobile with experience across many industries,
                        project types, and platforms. We utilize the latest
                        development frameworks to deliver a fully functional
                        solution and are focused on finding the best solutions
                        for every business need.
                      </p>
                      <p>
                        We use a continuous delivery approach that will help you
                        get to market faster ensuring we deliver products that
                        are built for scale, performance and longevity.
                      </p>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
            <br />
            <AdditionalServiceListComponent />
          </div>
        </main>
      </EasyTransition>
    );
  }
}

export default Development;
