import React, { Component } from 'react';
import BlogListItem from '../blog-list-item/blog-list-item';

import './blog-archive.scss';

class BlogArchive  extends Component {

    items: any;

    constructor(props) {
        super(props);

        this.items = [
            {
                title: 'January 10 2018'
            },
            {
                title: 'July 10 2018'
            },
            {
                title: 'March 20 2018'
            },
            {
                title: 'June 15 2017'
            }
        ];
    }

    render(){

        return(
            <div className="widget archive-widget pb-20">
                <h4 className="widget-title h-title">Blog Archive</h4>
                <div className="widget-content pt-30 pb-5">
                    <ul className="sidebar-list">
                    {
                        this.items.map((date, index, props) => {
                            return (<BlogListItem {...date} key={`date-${index}`} />);
                        })
                    }
                    </ul>
                </div>
            </div>
        )
    }


}

export default BlogArchive;