import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import BannerComponent from "../../../components/banner/banner";

import { canUseWebP } from "../../../helpers";
import designBannerImage from "../../../images/services-headers/design2.jpg";
import designBannerImageWebP from "../../../images/services-headers/design2.webp";
import AdditionalServiceListComponent from "../../../components/additional-service-list/additional-service-list";
import EasyTransition from "react-easy-transition";

import "./design.scss";

class Design extends Component {
  constructor(props) {
    super(props);
    this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
    return null;
  }

  render() {
    return (
      <EasyTransition
        path={"../design/design.js"}
        initialStyle={{ opacity: 0, marginTop: 30 }}
        transition="all 300ms linear"
        finalStyle={{ opacity: 1, marginTop: 0 }}
      >
        <main className="main-content">
          <div className="page-container">
            <BannerComponent
              img={canUseWebP() ? designBannerImageWebP : designBannerImage}
              title="Design"
              paragraph="Informed by research and driven by a strong understanding of User Experience totransmit our creative ideas and concepts."
            />

            <section
              className="section our-process-info pt-60 pb-20"
              id="services"
            >
              <Container>
                <Row className="mb-30">
                  <Col>
                    <p>
                      We work with individuals and businesses from the ground up
                      focusing on UX strategy during the early stages of a
                      project. Our designs are informed by research, your brand
                      and audience in order to turn ideas into solid prototypes
                      and designs. We are driven to create memorable
                      experiences, designing effective solutions that balance
                      audiences needs with client requirements.
                    </p>
                  </Col>
                </Row>
              </Container>
            </section>

            <section className="section our-design-item">
              <Container>
                <Row className="row-tb-15 mt-40">
                  <Col md="6">
                    <Card>
                      <h2>Content Development</h2>
                      <p>
                        We ensure that the tone, voice and subject matter of
                        your project is consistent throughout the entire
                        experience.
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <h2>Usability Testings</h2>
                      <p>
                        Share, test and collaborate to help us plan, prototype
                        and create user-first initiatives dedicated to the
                        customer experience.
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <h2>Mobile and Web Design</h2>
                      <p>
                        Goal-oriented, flexible design and rapid prototyping to
                        create informed user interfaces and smart technology
                        with decisions driven by data, knowledge and experience.
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <h2>Interaction Design</h2>
                      <p>
                        From rapid prototyping to production we focus on the
                        customer experience ensuring that every interaction
                        delivers value for you and your customers.
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <h2>User Experience & User Interface Design</h2>
                      <p>
                        User-centered thinking is at the core of any successful
                        interface, application or web page. We put the user
                        first when considering all aspects of design. We
                        understand that it isn’t just about creating beautiful
                        interfaces. We weigh form and function equally to
                        recognize the best way to deliver products and
                        experiences.
                      </p>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <h2>Brand Identity </h2>
                      <p>
                        We work together with you to establish your visual
                        identity. We don’t just create logos, we determine
                        color, fonts, graphic styles, brandmark, photography,
                        key messages and the voice and tone of your brand to
                        create a visual expression of your company.
                      </p>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
            <br />
            <AdditionalServiceListComponent />
          </div>
        </main>
      </EasyTransition>
    );
  }
}

export default Design;
