import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import placeHolder1 from '../../../images/blog/1.png';

class ContentPost  extends Component {

    render(){
        return(
            <div className="col-xs-12">
                <article className="entry bg-white">
                    <div className="entry-media">
                        <Link to="#">
                            <div className="embed-responsive embed-responsive-16by9">
                                <img src={placeHolder1} alt="placeholder"/>
                            </div>
                        </Link>
                    </div>
                    <div className="entry-content pt-40 p-30">
                        <h2 className="mb-10 font-24">{this.props.title}</h2>
                        <p className="color-mid mb-0">{this.props.desc}</p>
                    </div>
                    <div className="entry-footer pt-20 p-30">
                        <div className="clearfix">
                            <div className="pull-left">
                                <div className="author">
                                    <Link to="#"><h5><img src="assets/images/blog/author-small.jpg" className="circle mr-10" alt=""/>{this.props.user}</h5></Link>
                                </div>
                            </div>
                            <div className="pull-right">
                                <Link to="#" className="btn read-more">{this.props.btn}</Link>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }

}

export default ContentPost;