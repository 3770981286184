import React, { Component } from "react";
import { Parallax } from "react-scroll-parallax";

import "./process-item.scss";

class ProcessItemComponent extends Component {
  render() {
    return (
      <section className="section our-process-item">
        <div className="container">
          <div className="row">
            <div
              className={!this.props.haveRightSide ? "show-for-md" : "col-md-6"}
            >
              <Parallax
                className="custom-class"
                offsetYMax={20}
                offsetYMin={-20}
                slowerScrollR
              >
                <img src={this.props.image} alt="{this.props.image}" />
              </Parallax>
            </div>
            <div className="col-md-6 our-process-info">
              <div>
                <h2 className="t-uppercase">{this.props.title}</h2>
                <p>{this.props.paragraph}</p>
                <p>{this.props.paragraph1}</p>
                <p>{this.props.paragraph2}</p>
              </div>
            </div>
            {!this.props.haveRightSide && (
              <div className="col-md-6 hide-for-md">
                <Parallax
                  className="custom-class"
                  offsetYMax={20}
                  offsetYMin={-20}
                  slowerScrollR
                >
                  <img src={this.props.image} alt="{this.props.image}" />
                </Parallax>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default ProcessItemComponent;
