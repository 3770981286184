import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./additional-service-card.scss";

class AdditionalServiceCardComponent extends Component {
  render() {
    return (
      <div className="col-md-2 col-sm-6 col-xs-12">
        <div className="service-single pt-50">
          <div className="service-content pt-5">
            <img src={this.props.imageIcon} alt="Additional Services Icon" />
            <h2 className="t-uppercase">{this.props.title}</h2>
            {this.props.hasText && <p>{this.props.text}</p>}
            {!this.props.hasText && (
              <Link to={this.props.link}>
                <i className="fa fa-arrow-circle-o-right" aria-hidden="true" />
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AdditionalServiceCardComponent;
