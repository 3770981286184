import React, { Component } from "react";
import { Nav, Navbar, NavDropdown, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import logo from "../../images/devlift_logo/minilogoColor.svg";
import logoWhite from "../../images/devlift_logo/minilogoColorWhite.svg";
import "./header.scss";

import PropTypes from "prop-types";

class Header extends Component {
  getInTouch() {
    const formEl = document.getElementById("contact");
    if (formEl) {
      formEl.scrollIntoView({ behavior: "smooth" });
    } else {
      // window.location.href = "/#contact"; //last resort!!!!!
    }
  }
  scrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };
  render() {
    const { showCollapsed } = this.props;
    return (
      <header
        className={
          "main-header has-transparent" + (showCollapsed ? "" : " transparent")
        }
      >
        <Navbar collapseOnSelect={true} expand="lg" fixed="top">
          <Container>
            <Navbar.Brand>
              <LinkContainer
                to="/"
                onClick={this.scrollToTop}
                className={
                  "d-block d-xs-none black-logo" +
                  (showCollapsed ? " show-logo" : " hide-logo")
                }
              >
                <img src={logo} alt="Devlift Media Logo" />
              </LinkContainer>
              <LinkContainer
                to="/"
                className={
                  "d-none d-xs-block white-logo" +
                  (showCollapsed ? " hide-logo" : " show-logo")
                }
              >
                <img src={logoWhite} alt="Devlift Media Logo" />
              </LinkContainer>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
              <Nav className="ml-auto">
                <NavDropdown title="Services" id="basic-nav-dropdown">
                  <LinkContainer to="/strategy" activeClassName="active-link">
                    <NavDropdown.Item>Strategy</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/design" activeClassName="active-link">
                    <NavDropdown.Item>Design</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    to="/development"
                    activeClassName="active-link"
                  >
                    <NavDropdown.Item>Development</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/marketing" activeClassName="active-link">
                    <NavDropdown.Item>Marketing</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    to="/build-your-team"
                    activeClassName="active-link"
                  >
                    <NavDropdown.Item>Build your Team</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
                <LinkContainer to="/our-process" activeClassName="active-link">
                  <Nav.Link>Process</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/about" activeClassName="active-link">
                  <Nav.Link>About</Nav.Link>
                </LinkContainer>
                {/* <LinkContainer to="/the-lab" activeClassName="active-link">
                  <Nav.Link>The Lab</Nav.Link>
                </LinkContainer> */}
                <li className="signup">
                  <LinkContainer to="/#contact">
                    <Nav.Link
                      className="btn"
                      onClick={this.getInTouch.bind(this)}
                    >
                      Get In Touch
                    </Nav.Link>
                  </LinkContainer>
                </li>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
Header.propTypes = {
  showCollapsed: PropTypes.bool
};

export default Header;
