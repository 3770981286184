import React, { Component } from "react";
import { Link } from "react-router-dom";
// import Slider from 'react-slick';

import { canUseWebP } from "../../helpers";

import BannerComponent from "../../components/banner/banner";
import AboutHeader from "../../images/aboutHeader.jpg";
import AboutHeaderWebP from "../../images/aboutHeader.webp";
import RecentPosts from "./recent-posts/recent-posts";
import BlogCategories from "./blog-categories/blog-categories";
import BlogArchive from "./blog-archive/blog-archive";
import ContentPost from "./content-post/content-post";

// import placeHolder1 from "../../images/blog/1.png";

import "./the-lab.scss";

class TheLab extends Component {
  render() {
    // const sliderSettings = {
    //     dots: false,
    //     infinite: true,
    //     easing: 'linear',
    //     autoplay: true,
    //     speed: 600,
    //     swipeToSlide: true,
    // };

    return (
      <main className="main-content">
        <div className="page-container">
          <BannerComponent
            img={canUseWebP() ? AboutHeaderWebP : AboutHeader}
            title="Our Lab"
            paragraph="We look at the whole picture to determine what is right for your business and audience"
          />

          {/*Start Blog Area */}
          <div className="section blog-area blog-classNameic bg-gray ptb-50">
            <div className="container">
              <div className="row">
                <div className="col-md-8 blog-content ptb-40 prl-20">
                  <div className="row row-tb-20 mb-40">
                    <ContentPost
                      title="25 Tricks That Will Increase Your Productivity"
                      desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                      btn="Continue Reading"
                      user="Wafi"
                    />

                    <div className="col-xs-12">
                      <article className="entry bg-white">
                        <div className="">
                          {/* <Slider {...sliderSettings}>
                                                <div className="">
                                                    <img src={placeHolder1} alt="placeholder" />
                                                </div>
                                                <div className="">
                                                    <img src={placeHolder1} alt="placeholder"/>
                                                </div>
                                                <div className="">
                                                    <img src={placeHolder1} alt="placeholder"/>
                                                </div>
                                            </Slider> */}
                        </div>

                        <div className="entry-content pt-40 p-30">
                          <h2 className="mb-10 font-24">
                            25 Tricks That Will Increase Your Productivity
                          </h2>
                          <p className="color-mid mb-0">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Phasellus pretium orci sit amet mi ullamcorper
                            egestas. Sed non mattis metus. Integer vel lorem
                            tincidunt, pharetra eros nec, posuere odio. Nullam
                            eget bibendum sem, venenatis lacinia justo [...]
                          </p>
                        </div>

                        <div className="entry-footer pt-20 p-30">
                          <div className="clearfix">
                            <div className="pull-left">
                              <div className="author">
                                <Link to="#">
                                  <h5>
                                    <img
                                      src="assets/images/blog/author-small.jpg"
                                      className="circle mr-10"
                                      alt=""
                                    />
                                    David Turner
                                  </h5>
                                </Link>
                              </div>
                            </div>
                            <div className="pull-right">
                              <Link to="#" className="btn read-more">
                                Continue Reading...
                              </Link>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 blog-sidebar ptb-40">
                  <aside className="sidebar blog-sidebar">
                    <div className="row row-tb-10">
                      <div className="col-xs-12">
                        {/* Search Form */}
                        <div className="widget search-form">
                          <div className="widget-body">
                            <form method="post" action="#">
                              <div className="field-search">
                                <input
                                  type="text"
                                  className="form-control input-lg"
                                  placeholder="Search for..."
                                />
                                <Link to="#" className="btn-search">
                                  <i className="fa fa-search font-16" />
                                </Link>
                              </div>
                            </form>
                          </div>
                        </div>
                        {/* End Search Form */}
                      </div>
                      <div className="col-xs-12">
                        {/* Recent Posts */}
                        <RecentPosts />
                        {/* End Recent Posts */}
                      </div>
                      <div className="col-xs-12">
                        {/* Categories Widget */}
                        <BlogCategories />
                        {/* End Categories Widget */}
                      </div>
                      <div className="col-xs-12">
                        <BlogArchive />
                      </div>
                      <div className="col-xs-12">
                        {/* Instagram Widget */}
                        <div className="widget instagram-widget pb-20">
                          <h4 className="widget-title h-title">Instagram</h4>
                          <div className="widget-body pt-30 pb-5">
                            <div className="row row-tb-5 row-rl-5">
                              <div className="instagram-widget__item col-xs-4">
                                <img
                                  src="assets/images/instagram/1.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="instagram-widget__item col-xs-4">
                                <img
                                  src="assets/images/instagram/2.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="instagram-widget__item col-xs-4">
                                <img
                                  src="assets/images/instagram/3.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="instagram-widget__item col-xs-4">
                                <img
                                  src="assets/images/instagram/4.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="instagram-widget__item col-xs-4">
                                <img
                                  src="assets/images/instagram/5.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="instagram-widget__item col-xs-4">
                                <img
                                  src="assets/images/instagram/6.jpg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* End Instagram Widget */}
                      </div>
                      <div className="col-xs-12">
                        <div className="widget tags-widget pb-20">
                          <h4 className="widget-title h-title">Tags</h4>
                          <div className="widget-content pt-30 pb-5">
                            <div className="tags-list clearfix">
                              <Link to="#">Art</Link>
                              <Link to="#">Creative</Link>
                              <Link to="#">Latest</Link>
                              <Link to="#">Awesome</Link>
                              <Link to="#">Custom</Link>
                              <Link to="#">Bootstrap</Link>
                              <Link to="#">bootstrap</Link>
                              <Link to="#">Photoshop</Link>
                              <Link to="#">Themeforest</Link>
                            </div>
                          </div>
                        </div>
                        {/* End Subscribe Widget */}
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
          {/* End Blog Area */}
        </div>
      </main>
    );
  }
}

export default TheLab;
